<template>
  <vx-card id="empty-bot-wrapper">
    <div class="container">
      <img
        class="empty-bot"
        src="@/assets/images/bot-image.svg"
        alt="Bot image"
      />
      <br />
      <h4>{{ lang.metrics.emptyBot[languageSelected] }}</h4>

      <br />
      <vs-button :to="{ path: 'bot-maker/add' }">{{
        lang.metrics.createBot[languageSelected]
      }}</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EmptyBots',
  computed: {
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>

<style lang="scss" scoped>
#empty-bot-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .empty-bot {
    max-height: 200px;
  }
}
</style>
